import googleMapsApiLoader from 'google-maps-api-loader';
import { throttle } from 'lodash-es';

function getVisibleMapItems(map, mapItems) {
  const currentBounds = map.getBounds();
  const visibleMapItems = [...mapItems].filter(item => currentBounds.contains(item.marker.getPosition()));
  return visibleMapItems;
}


/**
 * Send event for AlpineJS.
 */
function dispatchVisibleMarkerEvent(visibleMapItems) {
  const activeIDs = visibleMapItems.map(item => Number(item.el.dataset.id));
  const updateEvent = new CustomEvent('retailmap-update-visible-markers', {
    bubbles: true,
    cancelable: true,
    detail: {
      activeIDs,
    },
  });
  window.dispatchEvent(updateEvent);
}

function initFiltering(googleApi, map, mapItems) {

  /**
   * Filtering and stuff
   */

  let boundsChangedFromOutside = false;

  // Trigger an event for AlpineJS to use.
  const getVisibleMapItemsThrottled = throttle(getVisibleMapItems, 250);
  const dispatchVisibleMarkerEventThrottled = throttle(dispatchVisibleMarkerEvent, 250);
  googleApi.maps.event.addListener(map, 'bounds_changed', () => {
    const visibleMapItems = getVisibleMapItemsThrottled(map, mapItems);
    if (!boundsChangedFromOutside) {
      dispatchVisibleMarkerEventThrottled(visibleMapItems);
    }
  });
  googleApi.maps.event.addListener(map, 'dragend', () => {
    const visibleMapItems = getVisibleMapItemsThrottled(map, mapItems);
    if (!boundsChangedFromOutside) {
      dispatchVisibleMarkerEventThrottled(visibleMapItems);
    }
  });

  // Listen to city <select> change
  window.addEventListener('retailmap-update-active-city', (event) => {
    // Prevent triggering the custom event dispatcher in "bounds_changed" event
    boundsChangedFromOutside = true;

    const newBounds = new googleApi.maps.LatLngBounds();
    const activeCityMarkers = mapItems.filter(item => (
      !event.detail.activeCity || event.detail.activeCity === item.el.dataset.city
    ));

    activeCityMarkers.forEach(item => item.marker && newBounds.extend(item.marker.getPosition()));
    map.setOptions({ maxZoom: 13 });
    map.fitBounds(newBounds);

    googleApi.maps.event.addListenerOnce(map, 'idle', () => {
      // Restore bounds_changed custom event dispatcher
      boundsChangedFromOutside = false;
      map.setOptions({ maxZoom: undefined });
    });
  });
}


function init() {
  const mapEls = document.querySelectorAll('.b-google-map') || [];

  if (!mapEls.length) {
    return;
  }

  const { apiKey } = mapEls[0].querySelector('.b-google-map__canvas').dataset;

  googleMapsApiLoader({
    libraries: ['places'],
    apiKey,
  }).then((googleApi) => {
    mapEls.forEach((mapEl) => {
      const mapCanvasEl = mapEl.querySelector('.b-google-map__canvas') || [];
      const mapItemEls = mapEl.querySelectorAll('[data-lat]');

      // const initialCenter = {
      //   lat: Number(mapItemEls[0].dataset.lat),
      //   lng: Number(mapItemEls[0].dataset.lng),
      // };

      const bounds = new googleApi.maps.LatLngBounds();
      const infowindow = new googleApi.maps.InfoWindow();
      const map = new googleApi.maps.Map(mapCanvasEl, {
        // center: initialCenter,
        // zoom: 13,
        // disableDefaultUI: true,
        // gestureHandling: 'none',
        // zoomControl: false,
        mapTypeControl: false,
        streetViewControl: true,
        zoom: 6,
        center: new google.maps.LatLng(62.6, 25.7333),
        styles: [
          {
            stylers: [
              { saturation: 0 },
              { lightness: 0 },
            ],
          },
        ],
      });

      const mapItems = [...mapItemEls]
        .filter(el => el.dataset.lat && el.dataset.lng)
        .map((el) => {
          const marker = new googleApi.maps.Marker({
            position: {
              lat: Number(el.dataset.lat),
              lng: Number(el.dataset.lng),
            },
            title: el.dataset.title,
            icon: {
              //url: `${window.location.origin}/wp-content/themes/kastelli-theme/src/assets/images/map-marker.svg`,
              url: el.dataset.company === 'hirsi'
                ? `${window.location.origin}/wp-content/themes/kastelli-theme/src/assets/images/map-marker-hirsi.svg`
                : `${window.location.origin}/wp-content/themes/kastelli-theme/src/assets/images/map-marker.svg`,
              size: new googleApi.maps.Size(55, 59),
              // size: new googleApi.maps.Size(116, 318),
              // scaledSize: new googleApi.maps.Size(58, 159),
              anchor: new googleApi.maps.Point(27, 58),
            },
            // map,
          });

          marker.setMap(map);

          bounds.extend(marker.getPosition());

          if (el.innerHTML.trim) {
            marker.addListener('click', () => {
              infowindow.setContent(el.innerHTML);
              infowindow.open(map, marker);
            });
          }

          return {
            el,
            marker,
          };
        });

      // No items on map. Set bounds to FINLAAAAAND
      if (!mapItems.length) {
        const geocoder = new googleApi.maps.Geocoder();
        geocoder.geocode({ address: 'Finland' }, (results, status) => {
          if (status === googleApi.maps.GeocoderStatus.OK) {
            // map.setCenter(results[0].geometry.location);
            map.fitBounds(results[0].geometry.bounds);
          }
        });
      }

      googleApi.maps.event.addListenerOnce(map, 'bounds_changed', () => {
        if (map.getZoom() > 13) {
          map.setZoom(13);
        }
      });

      map.fitBounds(bounds);


      if (
        !document.body.classList.contains('single-kst-presentation')
        && !document.body.classList.contains('single-kst-retail-office')
      ) {
        initFiltering(googleApi, map, mapItems);
      }
    });
  }, (err) => {
    console.error(err);
  });
}

export default {
  init,
};
