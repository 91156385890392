/* global $ */

function init() {
  $(document).on('gform_post_render', (event, formID, currentPageID) => {

    if (formID !== 20) {
      return;
    }

    const formEl = document.querySelector('#gform_20');
    const blockHeaderEl = document.querySelector('.js-b-quiz__header');
    const fieldEls = formEl.querySelectorAll('.gfield');

    // Hide elements above form (heading, lead-paragraph etc.)
    formEl.addEventListener('change', () => {
      blockHeaderEl.setAttribute('aria-hidden', true);
      blockHeaderEl.style.height = `${blockHeaderEl.offsetHeight}px`;
      requestAnimationFrame(() => {
        blockHeaderEl.style.height = '0px';
      });
    });

    // Reset input values, in case of page reload
    [...formEl.querySelectorAll('input[type="radio"]')].forEach((inputEl) => {
      inputEl.checked = false;
    });

    // Show first question
    fieldEls[0].classList.add('active');

    // Hide answered question, show next.
    formEl.addEventListener('change', (event) => {
      const fieldEl = event.target.closest('.gfield');
      fieldEl.classList.remove('active');
      if (fieldEl.nextElementSibling) {
        fieldEl.nextElementSibling.classList.add('active');
      } else {
        formEl.submit();
      }
    });
  });
}

export default {
  init,
};
