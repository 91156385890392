// Polyfills
// import 'babel-polyfill';
import 'intersection-observer';
import 'focus-visible';
import 'element-qsa-scope';
import focusWithinPolyfill from 'focus-within';
import smoothScrollPolyfill from 'smoothscroll-polyfill';

// Plugins
import Cookies from 'js-cookie';
import choices from '../../components/choices/choices';
import magnificPopup from '../../components/magnific-popup/magnific-popup';
// import { debounce } from 'lodash-es';

// Components
import navDesktop from '../../components/nav-desktop/nav-desktop';
import navPanel from '../../components/nav-panel/nav-panel';
import navMobile from '../../components/nav-mobile/nav-mobile';
import topBar from '../../components/top-bar/top-bar';
import lazyLoad from '../../components/lazy-load/lazy-load';
import gravityForms from '../../components/gravity-forms/gravity-forms';
import priceDisplay from '../../components/price-display/price-display';
import likeSystem from '../../components/like-system/like-system';
import productListFilters from '../../components/product-list-filters/product-list-filters';
import fixedSideNav from '../../components/fixed-side-nav/fixed-side-nav';
import genericEventTracking from '../../components/generic-event-tracking/generic-event-tracking';
// import search from '../../components/search/search';

// Blocks
import banner from '../../blocks/banner/banner';
import imageGallery from '../../blocks/image-gallery/image-gallery';
import googleMap from '../../components/google-map/google-map';
import quiz from '../../blocks/quiz/quiz';
import yearByYear from '../../blocks/year-by-year/year-by-year';
import searchProductLift from '../../blocks/search-product-lift/search-product-lift';

const appStart = () => {
  // Make WP-core jQuery available globally
  window.$ = window.jQuery;

  // Make Cookies.js available globally (sometimes used with AlpineJS)
  window.Cookies = Cookies;

  // Polyfills
  focusWithinPolyfill(document);
  smoothScrollPolyfill.polyfill();

  // Plugin setup & configs
  magnificPopup.init();
  choices.init();

  // Blocks & Components
  navDesktop.init();
  navPanel.init();
  navMobile.init();
  topBar.init();
  lazyLoad.init();
  gravityForms.init();
  banner.init();
  imageGallery.init();
  priceDisplay.init();
  likeSystem.init();
  googleMap.init();
  fixedSideNav.init();
  quiz.init();
  yearByYear.init();
  productListFilters.init();
  searchProductLift.init();
  genericEventTracking.init();
  // search.init();
};

// 'DOMContentLoaded' may fire before your script has a chance to run.
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', appStart);
} else {
  appStart();
}
