function updateYearByYear() {
  $('.b-year-by-year__item').each((index, el) => {
    if ( $(el).offset().top <= $(window).scrollTop() + $(window).height() * 0.75 && $(el).hasClass('is-hidden') ) {
      $(el).removeClass('is-hidden').addClass('bounce-in');
    }
  });
}

function init() {
  if ($('.b-year-by-year__item').length) {
    updateYearByYear();
    $(window).on('scroll', updateYearByYear);
  }
}

export default {
  init,
};
