function handleHover(event) {
  const listEl = event.currentTarget;
  const navEl = listEl.closest('nav');
  const subnavEl = listEl.querySelector('ul');
  const rect = listEl.getBoundingClientRect();
  const navWidth = navEl.offsetWidth;
  const subnavWidth = subnavEl ? subnavEl.offsetWidth : 0;
  const navOffset = (window.innerWidth - navWidth) / 2;
  const subnavWiderOffset = Math.max((subnavWidth - navWidth), 0) / 2;
  const subnavArrowOffset = rect.left + rect.width / 2 - navOffset + subnavWiderOffset;
  listEl.style.setProperty('--nav-level-1-width', `${navWidth}px`);
  listEl.style.setProperty('--subnav-arrow-offset', `${Math.round(subnavArrowOffset)}px`);
}

function init() {
  const navEl = document.querySelector('.nav-desktop');
  [...navEl.querySelectorAll('li.level-1')].forEach((el) => {
    el.addEventListener('mouseenter', handleHover);
  });
}


export default {
  init,
};
