import {
  Swiper,
  Navigation,
  Pagination,
  A11y,
  Autoplay,
  EffectFade,
} from 'swiper/swiper.esm';

// const btnPrev = document.querySelector('[data-action="swiper-previous"]');
// const btnNext = document.querySelector('[data-action="swiper-next"]');
const btnScrollToNextBlock = document.querySelectorAll('[data-action="scroll-to-next"]');

function registerButtonEvents() {
  [...btnScrollToNextBlock].forEach((el) => {
    const closest = el.closest('.b-banner');
    let nextSegment = false;

    if (closest) {
      nextSegment = closest.nextElementSibling;
    }

    if (nextSegment) {
      if (nextSegment.id) {
        el.hash = nextSegment.id;
      }

      el.addEventListener('click', () => {
        nextSegment.scrollIntoView({ behavior: 'smooth' });
      });
    }
  });
}

function setImageHeightProp(swiper) {
  const firstCardImageEl = swiper.slides[0].querySelector('picture');
  const blockEl = swiper.wrapperEl.closest('.swiper-custom-block');
  blockEl.style.setProperty('--banner-image-height', `${firstCardImageEl.offsetHeight}px`);
}

// function toggleVideos(obj) {
//   const { slides, videos } = obj;
//   const currentIndex = obj.swiper.activeIndex;
//   const video = slides[currentIndex].querySelector('video');

//   [...videos].forEach((v) => { v.pause(); });
//   if (video) { video.play(); }
// }

// function updateTextColor(obj) {
//   const { slides } = obj;
//   const currentIndex = obj.swiper.activeIndex;
//   const currentSlideEl = slides[currentIndex];
//   const currentTextColor = currentSlideEl.querySelector('.b-carousel-item').dataset.textColor;
//   slides[currentIndex].closest('.b-carousel').setAttribute('data-current-text-color', currentTextColor);
// }

function mountCarousels(carouselEls) {
  const sliders = [];

  Swiper.use([Navigation, Pagination, A11y, Autoplay, EffectFade]);

  [...carouselEls].forEach((carouselEl, idx) => {
    const wrapperEl = carouselEl.closest('.b-banner');
    const slides = carouselEl.querySelectorAll('.swiper-slide');
    const videos = carouselEl.querySelectorAll('.swiper-slide video');
    // const btnPlayPause = carouselEl.querySelector('.swiper-autoplay-toggle');

    if (slides.length <= 0) {
      return;
    }

    const swiper = new Swiper(carouselEl.querySelector('.swiper-container'), {
      // simulateTouch: false,
      speed: 500,
      autoplay: {
        delay: 5000,
      },
      effect: 'fade',
      // fadeEffect: {
      //   crossFade: true,
      // },
      pagination: {
        el: wrapperEl.querySelector('.swiper-pagination'),
        type: 'bullets',
        clickable: true,
        bulletElement: 'button',
      },
      navigation: {
        nextEl: wrapperEl.querySelector('.swiper-button-next'),
        prevEl: wrapperEl.querySelector('.swiper-button-prev'),
      },
      a11y: {
        paginationBulletMessage: document.documentElement.lang === 'fi' ? 'Siirry diaan {{index}}' : 'Go to slide {{index}}',
      },
      // autoHeight: true,
      init: () => {
        // updateTextColor(sliders[idx]);
      },
      on: {
        init: setImageHeightProp,
        resize: setImageHeightProp,
      },
    });

    swiper.on('slideChange', () => {
      // updateShortcuts();
      // toggleVideos(sliders[idx]);
      // updateTextColor(sliders[idx]);
    });

    swiper.on('slideChange', () => {
      // updateShortcuts();
      // toggleVideos(sliders[idx]);
      // updateTextColor(sliders[idx]);
    });

    // btnPrev.addEventListener('click', () => swiper.slidePrev());
    // btnNext.addEventListener('click', () => swiper.slideNext());

    // btnPlayPause.addEventListener('click', () => {
    //   if (swiper.autoplay.running) {
    //     swiper.autoplay.stop();
    //     btnPlayPause.setAttribute('aria-pressed', false);
    //   } else {
    //     swiper.autoplay.start();
    //     btnPlayPause.setAttribute('aria-pressed', true);
    //   }
    // });

    sliders[idx] = {
      swiper,
      slides,
      videos,
    };
  });
}

function init() {
  const carouselEls = document.querySelectorAll('.b-banner');
  if (carouselEls.length) {
    mountCarousels(carouselEls);
    if (btnScrollToNextBlock) {
      registerButtonEvents();
    }
  }
}

export default {
  init,
};
