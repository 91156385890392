import Cookies from 'js-cookie';
import A11yDialog from 'a11y-dialog';
import { disableBodyScroll, enableBodyScroll /* , clearAllBodyScrollLocks */ } from 'body-scroll-lock';

let scrollYtmp = 0;

const bodyScrollOptions = {
  reserveScrollBarGap: true,
};

let dialogEl;
let dialogInstance;

// Note: this returns cites data as they are output in to <script> tag global variable,
// with snake_case naming: city_id, city_name
function getCityData(cityId) {
  const city = window.cities && Object.values(window.cities).find(item => String(item.city_id) === String(cityId));
  return city || {};
}

function getPriceGroup(cityId) {
  let readyToMovePriceGroup;
  const priceGroupTypes = ['mv_vvv1', 'mv_vvv2', 'mv_vvv3', 'mv_vvv4', 'mv_vvv5', 'talo'];
  const city = getCityData(cityId);

  if (!city) {
    console.warn('Price area city not found:', cityId);
    return '';
  }

  const cityPriceGroups = city.price_groups;

  if (!cityPriceGroups) {
    // Now what?
    return '';
  }

  for (let i = 0; i < cityPriceGroups.length; i++) {
    const priceGroup = cityPriceGroups[i];
    if (priceGroupTypes.includes(priceGroup)) {
      readyToMovePriceGroup = priceGroup;
      break;
    }
  }

  return readyToMovePriceGroup;
}

function getCurrentCity() {
  const cityId = Cookies.get('selectedCity') || 91;
  // const cityName = Cookies.get('selectedCityName') || 'Helsinki';
  const cityName = getCityData(cityId).city_name;
  return {
    cityId,
    cityName,
  };
}

function setCookie(cityId) {
  Cookies.set('selectedCity', cityId, { secure: true, expires: 365 });
  Cookies.set('selectedCityName', getCityData(cityId).city_name, { secure: true, expires: 365 });
}

function closeDialog() {
  if (dialogInstance) {
    dialogInstance.hide();
  }
}

function openDialog() {
  if (dialogInstance) {
    dialogInstance.show();
  }
}

function init() {

  // PE 10.11.23: Disable this to allow processing of KSTpriceArea
  /*
  if (!(window.cities && window.models)) {
    return;
  }
  */

  // Things to do on update
  window.addEventListener('updatecity', (event) => {
    // Save city to cookie
    setCookie(event.detail.cityId);

    const cityData = getCityData(event.detail.cityId);
    const cityName = cityData && cityData.city_name;

    // Analytics event
    if (cityName) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'click',
        category: 'Talomallistot',
        action: 'Paikkakunnan valinta',
        href: window.location.href,
        label: cityName,
      });
    }
  });

  // Declare variables & functions to global/window-scope, so AlpineJS can use them.
  window.KSTpriceArea = {
    getCityData,
    getCurrentCity,
    getPriceGroup,
    closeDialog,
    openDialog,
  };

  dialogEl = document.querySelector('#price-display-city-select');

  if (!dialogEl) {
    return;
  }
  dialogInstance = new A11yDialog(dialogEl);

  dialogInstance.on('show', () => {
    scrollYtmp = window.scrollY;
    disableBodyScroll(dialogEl, bodyScrollOptions);
    requestAnimationFrame(() => {
      window.scrollTo(0, scrollYtmp);
    });
  });

  dialogInstance.on('hide', () => {
    enableBodyScroll(dialogEl, bodyScrollOptions);
    requestAnimationFrame(() => {
      window.scrollTo(0, scrollYtmp);
    });
  });
}

export default {
  init,
};
