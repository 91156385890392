/* eslint-env browser */

import lazySizes from 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/blur-up/ls.blur-up';

export function refresh() {
  lazySizes.autoSizer.updateElems();
}

function init() {
  // Configs here
}

export default {
  init,
  refresh,
};
