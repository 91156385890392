/* global $ */

function setActivecampaignEmail(event, formId = '') {
  const activecampaignEmailEl = document.querySelector(`data.gform_activecampaign_email_${formId}`)
                                || document.querySelector('data.gform_activecampaign_email');
  if (activecampaignEmailEl) {
    // Set the email in localStorage
    const email = activecampaignEmailEl.getAttribute('value');
    localStorage.setItem('activeCampaignVisitorEmail', email);
  }
}

function init() {
  /**
   * Workaround for Gravity Forms missing filter for Consent field markup.
   * @link https://docs.gravityforms.com/gform_post_render/
   */
  $(document).on('gform_post_render', (event, formID, currentPageID) => {

    const formEl = document.querySelector(`#gform_${formID}`);

    [...formEl.querySelectorAll('.gfield_consent_label')].forEach((el) => {
      const markerHtml = `<span class="marker">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <symbol id="self" class="transition-color duration-150 ease" viewBox="0 0 24 24" width="24" height="24">
            <path fill="var(--icon-color, currentColor)" d="M9,16.17,4.83,12,3.41,13.41,9,19,21,7,19.59,5.59Z"/>
          </symbol>
          <use href="#self" />
        </svg>
      </span>`;
      el.insertAdjacentHTML('beforebegin', markerHtml);
    });

    /**
     * Autogrowing textarea
     * @link https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
     */
    [...formEl.querySelectorAll('.js-autogrow textarea')].forEach((el) => {
      el.removeAttribute('rows');
      el.removeAttribute('cols');
      el.classList.add('js-autogrow-active');
      el.addEventListener('input', (event) => {
        event.target.parentNode.dataset.replicatedValue = event.target.value;
      });
    });
  });


  /**
   * Activecampaign site tracking workaround
   * https://help.activecampaign.com/hc/en-us/articles/221542267-An-overview-of-Site-Tracking#a-third-option-for-identifying-your-contacts
   * https://community.activecampaign.com/t/because-when-a-user-registers-via-api-does-not-work-site-tracking/3299/4
   */
  // Non ajax-forms' thank you message
  // setActivecampaignEmail();
  // Ajax forms' thank you message
  // $(document).on('gform_confirmation_loaded', setActivecampaignEmail);
}

export default {
  init,
};
