/* global $ */

import Cookies from 'js-cookie';

/* Name 'undefined' results in broken like system, use direct setting below for now. */
//const { LIKE_SYSTEM_COOKIE_NAME } = window;

const LIKE_SYSTEM_COOKIE_NAME = 'favorite_posts';

function checkLoggedIn() {
  return window.wpApiSettings && window.wpApiSettings.nonce;
}

function getWishlistAJAX() {
  const apiUrl = `${window.wpApiSettings.root}wp/v2/users/me`;
  const data = {
    view: 'favorite_posts',
  };

  return $.ajax({
    url: apiUrl,
    method: 'GET',
    beforeSend: (xhr) => {
      xhr.setRequestHeader('X-WP-Nonce', window.wpApiSettings.nonce);
    },
    data,
  })
    .done((data, textStatus, jqXHR) => {
      if (jqXHR.status === 200) {
        // console.log('User wishlist loaded.');
      }
    })
    .fail((jqXHR, textStatus, errorThrown) => {
      console.log(jqXHR, textStatus, errorThrown);
    });
}

function saveWishlistAJAX(wishlist) {
  const apiUrl = `${window.wpApiSettings.root}wp/v2/users/me`;
  const data = {
    meta: {
      favorite_posts: wishlist.length ? wishlist : null,
    },
  };

  $.ajax({
    url: apiUrl,
    method: 'POST',
    beforeSend: (xhr) => {
      xhr.setRequestHeader('X-WP-Nonce', window.wpApiSettings.nonce);
    },
    data,
  })
    .done((data, textStatus, jqXHR) => {
      if (jqXHR.status === 200) {
        // console.log('User wishlist updated.');
      }
    })
    .fail((jqXHR, textStatus, errorThrown) => {
      console.log(jqXHR, textStatus, errorThrown);
    });
}

function getWishlist(initialLoad) {
  const isLoggedIn = checkLoggedIn();

  let wishlist = [];

  if (isLoggedIn && initialLoad) {
    getWishlistAJAX()
      .done((data) => {
        const wishlist = (data.meta && data.meta.favorite_posts) || [];
        saveWishlist(wishlist);
        dispatchEvent();
      });
  } else {

    const wishlistCookie = Cookies.get(LIKE_SYSTEM_COOKIE_NAME);
    //const wishlistCookie = Cookies.get('favorite_posts');

    if (wishlistCookie) {
      try {
        wishlist = JSON.parse(wishlistCookie);
      } catch (error) {
        //console.warn('Wishlist error', error);
        console.warn('Wishlist error');
        wishlist = [];
      }
    }
  }

  return wishlist;
}

function saveWishlist(wishlist) {
  //Cookies.set('favorite_posts', JSON.stringify(wishlist), { secure: true, expires: 365 });
  Cookies.set(LIKE_SYSTEM_COOKIE_NAME, JSON.stringify(wishlist), { secure: true, expires: 365 });
}

function dispatchEvent() {
  const wishlist = getWishlist();

  // Send event for AlpineJS
  const updateEvent = new CustomEvent('updatefavorite', {
    bubbles: true,
    cancelable: true,
    detail: {
      wishlist,
    },
  });
  window.dispatchEvent(updateEvent);
}

function updateWishlist(id, liked) {
  let wishlist = getWishlist();
  const inWishlist = wishlist.find(itemId => itemId === id);
  const isLoggedIn = checkLoggedIn();

  if (liked && !inWishlist) {
    wishlist.push(id);
  } else if (inWishlist) {
    wishlist = wishlist.filter(itemId => itemId !== id);
  }

  saveWishlist(wishlist);
  dispatchEvent();

  if (isLoggedIn) {
    saveWishlistAJAX(wishlist);
  }
}

function init() {
  // Listen to AlpineJS events
  window.addEventListener('togglefavorite', (event) => {
    updateWishlist(event.detail.id, event.detail.liked);

    // Analytics event
    if (event.detail.liked) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'click',
        category: 'Suosikki',
        action: 'Lisäys',
        label: `Talomallin lisääminen suosikiksi - ${event.detail.title}`,
      });
    }
  });

  // Add to global scope, for AlpineJS
  window.getWishlist = getWishlist;
}

export default {
  init,
};
