/* global $ */

/**
 * Magnific popup -plugin setup & configuration
 */

function init() {
  const galleryOptions = {
    gallery: {
      preload: [0, 2],
      enabled: true,
      arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button
      tCounter: '<span class="mfp-counter">%curr% / %total%</span>', // markup of counter
    },
    type: 'image',
    image: {
      titleSrc: 'title',
      markup: '<div class="mfp-figure">' +
      '<div class="mfp-close"></div>' +
      '<div class="mfp-img"></div>' +
      '<div class="mfp-bottom-bar">' +
      '<div class="mfp-title"></div>' +
      '<div class="mfp-counter"></div>' +
      '</div>' +
      '</div>',
    },
    iframe: {
      titleSrc: 'title',
      markup:
      '<div class="mfp-iframe-scaler">' +
      '<div class="mfp-close"></div>' +
      '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
      '<div class="mfp-bottom-bar">' +
      '<div class="mfp-title"></div>' +
      '<div class="mfp-counter"></div>' +
      '</div>' +
      '</div>'
    },
    callbacks: {
      markupParse: (template, values, item) => {
        values.title = item.el.attr('title');
      },
    },
    mainClass: 'mfp-fade',
    removalDelay: 300,
  };


  if (document.body.matches('.single-kst-house-model') || document.body.matches('.single-kst-outbuilding')) {
    $('body.single-kst-house-model').find('a.js-house-model-lightbox').magnificPopup(galleryOptions);

    $('.b-image-gallery:not(.b-image-gallery--carousel)').each((index, el) => {
      $(el).magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
          enabled: true,
        },
        removalDelay: 300,
        mainClass: 'mfp-fade',
        titleSrc: 'title',
      });
    });
  } else {
    $('.b-image-gallery').each((index, el) => {
      $(el).magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
          enabled: true,
        },
        removalDelay: 300,
        mainClass: 'mfp-fade',
        titleSrc: 'title',
      });
    });
    $('a.js-lightbox').magnificPopup(galleryOptions);
  }

  const iframeLighboxOptions = {
    type: 'iframe',
    // gallery: {
    //   enabled: true,
    // },
    removalDelay: 300,
    mainClass: 'mfp-fade',
    titleSrc: 'title',
    // callbacks: {
    //   markupParse: (template, values, item) => {
    //     values.title = item.el.attr('title');
    //   },
    // },
  };

  // Youtube lightboxes
  $('.b-pictorial-feature').find('a[href*="youtube"].c-btn, a.mfp-iframe').addClass('mfp-iframe').magnificPopup(iframeLighboxOptions);
}

export default {
  init,
};
