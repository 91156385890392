/* global $ */

/**
 * Generic link tracking
 */

function init() {
  $(document.body).on('click mouseup', (event) => {
    if (!['mouseup', 'touchend'].includes(event.type)) {
      return;
    }

    window.dataLayer = window.dataLayer || [];

    // Default values for analytics click/link events
    const gaEvent = {
      event: 'click',
      category: 'link',
    };

    // Track generic events
    if (event.target.matches('[data-event-on-click]')) {
      gaEvent.action = 'clickLink';
      gaEvent.label = event.target.href;
      gaEvent.href = event.target.href;
      gaEvent.text = event.target.textContent ? event.target.textContent.trim() : '';

      // Override default values with data-attribute values, if any
      if (event.target.dataset.eventOnClick) {
        let dataAttr = {};
        try {
          dataAttr = JSON.parse(event.target.dataset.eventOnClick);
        } catch (error) { console.log('Tracking error', error); }
        Object.assign(gaEvent, dataAttr);
      }

      window.dataLayer.push(gaEvent);
    } else {
      // Automatically track email links
      if (event.target.href && event.target.href.includes('mailto:')) {
        gaEvent.action = 'clickEmail';
        gaEvent.label = event.target.href.replace('mailto:', '');
        gaEvent.href = event.target.href;
        gaEvent.text = event.target.textContent ? event.target.textContent.trim() : '';
        window.dataLayer.push(gaEvent);
      }

      // Automatically track phone number links
      if (event.target.href && (event.target.href.includes('tel:') || event.target.href.includes('callto:'))) {
        gaEvent.action = 'clickPhoneNumber';
        gaEvent.label = event.target.href.replace('tel:', '').replace('callto:', '');
        gaEvent.href = event.target.href;
        gaEvent.text = event.target.textContent ? event.target.textContent.trim() : '';
        window.dataLayer.push(gaEvent);
      }

      // Automatically track links with a hash
      if (event.target.hash) {
        gaEvent.action = 'clickLink';
        gaEvent.label = event.target.hash;
        gaEvent.href = event.target.href;
        gaEvent.text = event.target.textContent ? event.target.textContent.trim() : '';
        window.dataLayer.push(gaEvent);
      }
    }
  });
}

export default {
  init,
};
