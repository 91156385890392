import Cookies from 'js-cookie';

function autoShowContactPopup() {
  const contactPopupEl = document.querySelector('.c-fixed-side-nav__contact-popup');

  const openTimeout = setTimeout(() => {
    // Send event for AlpineJS
    const updateEvent = new CustomEvent('contact-popup-toggle', {
      bubbles: true,
      cancelable: true,
      detail: {
        contactFormPopupOpen: true,
        autoShow: true,
      },
    });
    window.dispatchEvent(updateEvent);
  }, 30 * 1000);

  const closeTimeout = setTimeout(() => {
    // Send event for AlpineJS
    const updateEvent = new CustomEvent('contact-popup-toggle', {
      bubbles: true,
      cancelable: true,
      detail: {
        contactFormPopupOpen: false,
        autoShow: true,
      },
    });
    window.dispatchEvent(updateEvent);
  }, 60 * 1000);

  // Stop auto-behavior, if contact-popup opened from DOM
  window.addEventListener('contact-popup-toggle', () => {
    clearTimeout(openTimeout);
    clearTimeout(closeTimeout);
  });

  // Stop auto-close, if automatically opened contact-popup is interacted with
  contactPopupEl.addEventListener('click', () => {
    clearTimeout(closeTimeout);
  });
  contactPopupEl.addEventListener('focus', () => {
    clearTimeout(closeTimeout);
  });
}

function init() {
  const el = document.querySelector('.c-fixed-side-nav');

  if (!el) {
    return;
  }

  // Save "disable" value to cookie every time popup is opened or closed.
  window.addEventListener('contact-popup-toggle', () => {
    if (!Cookies.get('contactPopUp')) {
      Cookies.set('contactPopUp', 'disable', { expires: 1 });
    }
  });

  // Show auto-popup if no cookie, and desktop view.
  if (!Cookies.get('contactPopUp') && matchMedia('(min-width: 960px)').matches) {
    autoShowContactPopup();
  }

  // Add Google Analytics
  window.addEventListener('contact-popup-toggle', (event) => {
    if (!event.detail.contactFormPopupOpen || event.detail.autoShow) {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click',
      category: 'link',
      action: 'clickLink',
      label: 'Yhteydenottolaatikon avaus',
    });
  });

  // Add global event listener for hash-links
  window.addEventListener('click', (event) => {
    if (event.target.hash === '#contact-card-popup') {
      event.preventDefault();
      const updateEvent = new CustomEvent('contact-popup-toggle', {
        bubbles: true,
        cancelable: true,
        detail: {
          contactFormPopupOpen: true,
        },
      });
      window.dispatchEvent(updateEvent);
    }
  });
}

export default {
  init,
};
