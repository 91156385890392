import tinysort from 'tinysort';
import { throttle } from 'lodash-es';

let filterBlockEl;
let formEl;
let sortFormEl;
let itemEls;
let noResultsMessageEl;
let sortOrderControlEl;
let showNewFirstControlEl;
let advancedFiltersToggle;
let advancedFiltersEl;


/**
 * Advanced filters helper
 */
function toggleAdvancedFilters() {
  if (!(advancedFiltersToggle && advancedFiltersEl)) {
    return;
  }

  if (!advancedFiltersToggle.checked) {
    [...advancedFiltersEl.querySelectorAll('input, select')].forEach((el) => {
      resetFormField(el);
    });
  }

  advancedFiltersToggle.parentElement.querySelectorAll('.advanced-filters-toggle__content-on-show').forEach((el) => {
    el.classList.toggle('hidden', advancedFiltersToggle.checked);
  });

  advancedFiltersToggle.parentElement.querySelectorAll('.advanced-filters-toggle__content-on-hide').forEach((el) => {
    el.classList.toggle('hidden', !advancedFiltersToggle.checked);
  });

  advancedFiltersEl.classList.toggle('hidden', !advancedFiltersToggle.checked);
}


/**
 * Helper to reset a form field to its default (empty) state.
 */
function resetFormField(field) {
  if (field.tagName === 'TEXTAREA' || (field.tagName === 'INPUT' && ['text', 'number'].includes(field.type))) {
    field.value = field.defaultValue;
  } else if (field.tagName === 'INPUT' && ['radio', 'checkbox'].includes(field.type)) {
    field.checked = field.defaultChecked;
  } else if (field.tagName === 'SELECT') {
    [...field.children].forEach((option) => {
      option.selected = option.defaultSelected;
    });
  }
}


/**
 * Get FormData object from filter-form, with added fields from sort-form (if it exists).
 */
function getStateFromForm() {
  const formState = new FormData(formEl || undefined);

  // Combine Sort-form data to main form data
  if (sortFormEl) {
    const sortFormState = new FormData(sortFormEl);
    for (const pair of sortFormState.entries()) {
      formState.append(pair[0], pair[1]);
    }
  }

  return formState;
}


/**
 * Get filter criteria values from state, format them and give default values.
 * Default values basically allow all items to match, so that if no filter-selections are made,
 * all items are shown.
 */
function formatStateToSearchCriteria(state) {
  const filterNumberOfStoreys = state.getAll('number_of_storeys[]');
  let filterLivingArea = state.get('living_area');
  let filterFloorSpace = state.get('floor_space');
  const filterCatalog = state.getAll('catalog[]').map(value => Number(value));
  const filterPriceMin = Number(state.get('price_min')) * 1000;
  const filterPriceMax = Number(state.get('price_max')) * 1000 || Infinity;
  const filterTextSearch = (state.get('text_search') || '').split(' ');
  let filterBedrooms = state.getAll('bedrooms[]');

  filterLivingArea = filterLivingArea ? filterLivingArea.split('_').map(value => Number(value)) : [0, Infinity];
  filterFloorSpace = filterFloorSpace ? filterFloorSpace.split('_').map(value => Number(value)) : [0, Infinity];
  filterBedrooms = filterBedrooms.map(valuePair => valuePair.split('_').map(value => Number(value)));

  return {
    filterNumberOfStoreys,
    filterLivingArea,
    filterFloorSpace,
    filterCatalog,
    filterPriceMin,
    filterPriceMax,
    filterTextSearch,
    filterBedrooms,
  };
}


/**
 * Apply state to DOM. Update input values etc.
 */
function applyStateToForm(stateParams) {
  const state = new URLSearchParams(stateParams);
  const formControlEls = formEl ? formEl.querySelectorAll('input, select') : [];
  const sortFormEls = sortFormEl ? sortFormEl.querySelectorAll('input, select') : [];
  const allFormControlEls = [...formControlEls, ...sortFormEls];

  // Loop through all form control elements and update their value from state.
  [...allFormControlEls].forEach((el) => {
    if (el.tagName === 'INPUT' && ['text', 'number', 'hidden'].includes(el.type)) {
      el.value = state.get(el.name);
    } else if (el.tagName === 'INPUT' && el.type === 'radio') {
      el.checked = state.get(el.name) === el.value;
    } else if (el.tagName === 'INPUT' && el.type === 'checkbox') {
      el.checked = state.getAll(el.name).includes(el.value);
    } else if (el.tagName === 'SELECT') {
      const options = state.getAll(el.name);
      const choicesInstance = window.choicesInstances.get(el);
      if (choicesInstance) {
        // Select is using choices.js
        if (choicesInstance._isSelectMultipleElement) {
          // Removing values from multiselect doesn't work with just array of options.
          // Have to clear out all values and apply new ones.
          // choicesInstance.removeHighlightedItems(); // Why this doesn't work?
          const selectedValues = choicesInstance.getValue(true);
          selectedValues.forEach((value) => {
            choicesInstance.removeActiveItemsByValue(value);
          });
        }
        choicesInstance.setChoiceByValue(options);
      } else {
        [...el.children].forEach((optionEl) => {
          optionEl.selected = options.includes(optionEl.value);
        });
      }
    }
  });

  // Handle advanced filters
  toggleAdvancedFilters();
}


/**
 * Compare item to search criterias.
 */
function matchItemToSearchCriteria(el, searchCriteria) {
  const {
    filterNumberOfStoreys,
    filterLivingArea,
    filterFloorSpace,
    filterCatalog,
    filterPriceMin,
    filterPriceMax,
    filterTextSearch,
    filterBedrooms,
  } = searchCriteria;

  const cardEl = el.firstElementChild;
  const itemNumberOfStoreys = cardEl.dataset.numberOfStoreys
    ? cardEl.dataset.numberOfStoreys.split(';')
    : [];
  const itemLivingArea = Number(cardEl.dataset.livingArea);
  const itemFloorSpace = Number(cardEl.dataset.floorSpace);
  const itemCatalogs = cardEl.dataset.catalogs
    ? cardEl.dataset.catalogs.split(';').map(val => Number(val))
    : [];
  const itemBedrooms = Number(cardEl.dataset.bedrooms);
  const itemTitle = cardEl.dataset.title || '';
  //const currentCity = window.KSTpriceArea.getCurrentCity();
  const currentCity = [ '91', 'Helsinki' ];
  //const currentPriceGroup = window.KSTpriceArea.getPriceGroup(currentCity.cityId);
  const currentPriceGroup = 4;
  const itemReadyPrice = Number(cardEl.dataset[currentPriceGroup]);

  // Storeys: Test by checking if arrays have intersecting values
  const matchNumberOfStoreys = !filterNumberOfStoreys.length || filterNumberOfStoreys.filter(value => itemNumberOfStoreys.includes(value)).length;

  // Living area: Test that item value is between filter min & max
  const matchLivingArea = filterLivingArea[0] <= itemLivingArea && itemLivingArea <= filterLivingArea[1];

  // Floor space: Test that item value is between filter min & max
  const matchFloorSpace = filterFloorSpace[0] <= itemFloorSpace && itemFloorSpace <= filterFloorSpace[1];

  // itemCatalog: Test filter array includes item itemCatalog
  const matchCatalog = !filterCatalog.length || filterCatalog.filter(filterValue => itemCatalogs.includes(filterValue)).length;

  // Price: Test that item "ready" price is between filter min & max. Or item is missing price.
  const matchPrice = (filterPriceMin <= itemReadyPrice && itemReadyPrice <= filterPriceMax) || !itemReadyPrice;

  // Text search: Test that every keyword (separated by space) is included in the itemTitle
  const matchTextSearch = !filterTextSearch.length || filterTextSearch.every(term => itemTitle.toLowerCase().includes(term.toLowerCase()));

  // Bedroom: Test that item itemBedroom count is between any min & max filter value
  const matchBedrooms = !filterBedrooms.length || filterBedrooms.some(([min, max]) => min <= itemBedrooms && itemBedrooms <= max);

  return matchNumberOfStoreys && matchLivingArea && matchFloorSpace && matchCatalog && matchPrice && matchTextSearch && matchBedrooms;
}


/**
 * Update state to URL using History PushState.
 */
function updateURLstate() {
  const formState = getStateFromForm();
  const stateParams = new URLSearchParams(formState).toString();
  window.history.pushState({ stateParams }, '', `?${stateParams}`);
}


/**
 * Handle filtering, do changed to DOM
 */
function doFilter(shouldUpdateURL = true) {
  const formState = getStateFromForm();
  const searchCriteria = formatStateToSearchCriteria(formState);

  if (shouldUpdateURL) {
    updateURLstate();
  }

  // Hide all items initially
  [...itemEls].forEach(el => el.classList.add('hidden'));

  // Filter matching items
  const activeItems = [...itemEls].filter((el) => {
    return matchItemToSearchCriteria(el, searchCriteria);
  });

  // Show matching items
  activeItems.forEach(el => el.classList.remove('hidden'));

  // Toggle "No results" message
  noResultsMessageEl.classList.toggle('hidden', activeItems.length);
}


/**
 * Handle sorting, do changed to DOM
 */
function doSort(shouldUpdateURL = true) {
  const formState = getStateFromForm();
  const sortParam = formState.get('sort');
  const showNewFirstParam = formState.get('show_new_first');

  let orderby = sortParam ? sortParam.split('_')[0] : 'title';
  const order = sortParam ? sortParam.split('_')[1] : 'asc';
  const showNewFirst = showNewFirstParam === '1';
  const criterias = [];

  if (shouldUpdateURL) {
    updateURLstate();
  }

  if (showNewFirst) {
    criterias.push({ selector: '.js-b-house-model-card', data: 'new-model', order: 'desc' });
  }

  if (orderby === 'price-ready') {
    const currentCity = window.KSTpriceArea.getCurrentCity();
    const currentPriceGroup = window.KSTpriceArea.getPriceGroup(currentCity.cityId);
    orderby = currentPriceGroup;
  }

  if (orderby === 'title') {
    criterias.push({ selector: '.js-b-house-model-card', data: 'title', order });
  } else {
    criterias.push({ selector: '.js-b-house-model-card', data: orderby, order, emptyEnd: order === 'asc' });
    criterias.push({ selector: '.js-b-house-model-card', data: 'title', order: 'asc' });
  }

  tinysort(itemEls, ...criterias);
}


/**
 * Initialize everything.
 */
function init() {

  filterBlockEl = document.querySelector('.b-product-list-filters');
  sortFormEl = document.querySelector('.b-product-card-list__sort form');

  if (!(filterBlockEl || sortFormEl)) {
    return;
  }

  formEl = document.querySelector('.b-product-list-filters form');

  const formControlChoiceEls = formEl ? formEl.querySelectorAll('input[type="radio"], input[type="checkbox"]') : [];
  const formControlSelectEls = formEl ? formEl.querySelectorAll('select') : [];
  const formControlTextEls = formEl ? formEl.querySelectorAll('input[type="text"], input[type="number"], input[type="hidden"]') : [];
  noResultsMessageEl = document.querySelector('.b-product-card-list__no-results');
  itemEls = document.querySelectorAll('.b-product-card-list__item');

  const doFilterThrottled = throttle(doFilter, 500);

  /**
   * Register events
   */

  advancedFiltersEl = document.querySelector('#advanced-filters');
  advancedFiltersToggle = formEl && formEl.querySelector('input[name="show_advanced_filters"]');

  if (advancedFiltersToggle) {
    advancedFiltersToggle.addEventListener('change', () => {
      toggleAdvancedFilters();
    });
  }

  [...formControlChoiceEls].forEach((el) => {
    el.addEventListener('change', (event) => {
      if (event.isTrusted) {
        doFilterThrottled();
      }
    });
  });

  [...formControlTextEls].forEach((el) => {
    el.addEventListener('keyup', (event) => {
      if (event.isTrusted) {
        doFilterThrottled();
      }
    });
  });

  [...formControlSelectEls].forEach((el) => {
    el.addEventListener('change', (event) => {
      if (el.classList.contains('js-choice')) {
        doFilterThrottled();
      } else if (event.isTrusted) {
        doFilterThrottled();
      }
    });
  });


  // Register sorting events
  sortOrderControlEl = sortFormEl && sortFormEl.querySelector('[name="sort"]');
  showNewFirstControlEl = sortFormEl && sortFormEl.querySelector('[name="show_new_first"]');
  const doSortThrottled = throttle(doSort, 500);

  if (sortOrderControlEl) {
    sortOrderControlEl.addEventListener('change', (event) => {
      if (showNewFirstControlEl) {
        showNewFirstControlEl.checked = false;
      }

      if (sortOrderControlEl.classList.contains('js-choice')) {
        doSortThrottled();
      } else if (event.isTrusted) {
        doSortThrottled();
      }
    });
  }

  if (showNewFirstControlEl) {
    showNewFirstControlEl.addEventListener('change', (event) => {
      if (event.isTrusted) {
        doSortThrottled();
      }
    });
  }


  /**
   * Initial state
   */

  let initialStateParams = 'show_new_first=1';

  if (window.history && window.history.state) {
    initialStateParams = window.history.state.stateParams;
  } else if (window.location.search) {
    // Get all form field names,
    const fieldNames = [...(formEl && formEl.elements), ...(sortFormEl && sortFormEl.elements)]
      .map(fieldEl => fieldEl.name)
      .filter(v => v);
    const fieldNamesUnique = [...new Set(fieldNames)];

    // Compare search param keys to form field names
    const initialState = new URLSearchParams(window.location.search);
    const paramNames = [...initialState.keys()];
    const intersectingNames = paramNames.filter(x => fieldNamesUnique.includes(x));

    // Only set state from parameters if there is any match to actual field names.
    // To avoid setting state just because there is *any* param, like &seravo_shadow or some tracking params.
    if (intersectingNames.length) {
      initialStateParams = window.location.search;
    }
  }

  applyStateToForm(initialStateParams);
  doFilter(false);
  doSort(false);


  /**
   * Listen to history change (back/forward)
   */
  window.addEventListener('popstate', (event) => {
    applyStateToForm(event.state ? event.state.stateParams : '');
    doFilter(false);
    doSort(false);
  });

  /**
   * Listen to price area change
   */
  window.addEventListener('updatecity', () => {
    doFilter(false);
    doSort(false);
  });
}

export default {
  init,
};
