/* global $ */

function init() {
  const $searchHousesForm = $('form[name="search-houses"]');
  const $searchVillasForm = $('form[name="search-villas"]');

  if (!($searchHousesForm.length && $searchVillasForm.length)) {
    return;
  }

  window.dataLayer = window.dataLayer || [];

  $searchHousesForm.submit(() => {
    const storeys = $searchHousesForm.find('input[name="number_of_storeys[]"]:checked').map((index, el) => el.value).get().join(' & ');
    const size = $searchHousesForm.find('select[name="living_area"]').val();

    window.dataLayer.push({
      event: 'click',
      category: 'Hakukenttä',
      action: 'Haku',
      label: `Etusivun talomallihaku - kerroksia: ${storeys}, koko: ${size}`,
      href: window.location.href,
      text: 'Etusivun talomallihaku',
    });

    return true;
  });

  $searchVillasForm.submit(() => {
    const storeys = $searchVillasForm.find('input[name="number_of_storeys[]"]:checked').map((index, el) => el.value).get().join(' & ');
    const size = $searchVillasForm.find('select[name=floor_space]').val();

    window.dataLayer.push({
      event: 'click',
      category: 'Hakukenttä',
      action: 'Haku',
      label: `Etusivun huvilahaku - kerroksia: ${storeys}, koko: ${size}`,
      href: window.location.href,
      text: 'Etusivun huvilahaku',
    });

    return true;
  });
}

export default {
  init,
};
